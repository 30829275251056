<template>
  <div class="course-detail">
    <div class="main">
      <div class="cover" v-bind:style="{ backgroundImage: 'url(' + courseVO.course.coverCosUrl + ')' }">
        <div class="go-back" @click="onGoBack">
          <img :src="goBackImg" />
        </div>
      </div>
      <div class="panel">
        <div class="title">
          {{ courseVO && courseVO.course ? courseVO.course.courseName : "" }}
        </div>
        <!-- <div class="tag-list">
                    <span class="tag">中阶</span>
                    <span class="tag">明星导师</span>
                    <span class="tag warn">限时优惠</span>
                </div> -->
        <div class="tag-list">
          <span
            class="tag"
            v-for="(tag, j) in courseVO.course.tags"
            :key="j"
            v-bind:class="{ warn: tag.type == 'WARN' }"
            >{{ tag.name }}</span
          >
        </div>
        <div class="info">
          <div class="sale-info">{{ courseVO.course.scheduleCnt }}节课 已售：{{ courseVO.course.saleCount }}人</div>
          <div class="price-info">
            <span class="show">￥{{ courseVO.course.showPrice }}</span>
            <span class="true"> <span class="yuan">￥</span>{{ courseVO.course.truePrice }}</span>
          </div>
        </div>
        <div class="line"></div>
        <div class="tab-info">
          <!-- <div class="tab active">课程安排</div>
                    <div class="tab">课程介绍</div>
                    <div class="tab">老师介绍</div> -->
          <div
            class="tab"
            v-for="(item, i) in tabName"
            :key="i"
            :class="{ active: active == i }"
            @click="onClickTab(i)"
          >
            {{ item }}
          </div>
        </div>
        <div class="schedule" v-if="active == 0">
          <section v-for="(item, i) in courseVO.scheduleList" :key="i">
            <div v-if="item.teachId == 0" class="chapter">
              <span class="name">{{ item.scheduleName }}</span>
              <span class="tag">
                <img :src="arrowUpImg" />
              </span>
            </div>
            <div v-else class="learn-section" @click="gotoTeach(item)">
              <span class="name">{{ item.scheduleName }}</span>
              <span v-if="courseVO.ownThisCourse == 0 && item.authType == 1" class="tag">试看</span>
            </div>
          </section>
        </div>
        <div class="introduction" v-else-if="active == 1">
          <div v-html="courseVO.course.introduction"></div>
        </div>
        <div class="teachers-intro" v-else-if="active == 2">
          <div v-for="(item, i) in courseVO.course.teacherList" :key="i" class="teacher">
            <div class="left">
              <img :src="item.avatar" />
            </div>
            <div class="right">
              <div class="name">
                {{ item.teacherName }}
              </div>
              <div class="detail">
                {{ item.introduction }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="price-info">
        <span class="show">￥{{ courseVO.course.showPrice }}</span>
        <span class="true"><span class="yuan">￥</span>{{ courseVO.course.truePrice }}</span>
      </div>
      <div class="button-info">
        <button v-if="courseVO.ownThisCourse == 1" class="buy" :class="{ disable: courseVO.ownThisCourse == 1 }">
          购买成功
        </button>
        <button
          v-else-if="courseVO.course && courseVO.course.status == 1 && courseVO.ownThisCourse != 1"
          class="buy"
          @click="onBuy"
        >
          加入课程
        </button>
        <button
          v-else-if="courseVO.course && courseVO.course.status == 2 && courseVO.ownThisCourse != 1"
          class="buy disable"
        >
          停售
        </button>
      </div>
    </div>
    <div class="cs" @click="onClickShowCsWidget">
      <img :src="csBtn" />
    </div>

    <csWidget v-if="showCsWidget" :csId="'1'"></csWidget>
    <loginWidgetV2 v-if="showLoginWidget"></loginWidgetV2>
  </div>
</template>

<script>
import csWidget from "@/components/csWidget.vue";
import loginWidgetV2 from "@/components/loginWidgetV2.vue";
import { Notify } from "vant";
import { isMobile, isWeiXin } from "@/util/browser.js";
import { courseApi } from "@/api/courseApi.js";
import { userCenterApi } from "@/api/userCenterApi.js";
import { orderApi } from "@/api/orderApi.js";
export default {
  components: {
    csWidget,
    loginWidgetV2,
  },
  data() {
    return {
      active: 0,
      tabName: ["课程安排", "课程介绍", "老师介绍"],
      // seconds: COUNT_DOWN_SECOND,
      csBtn: require("@/assets/images/cs-btn.png"),
      arrowUpImg: require("@/assets/images/schedule-arrow-up.png"),
      goBackImg: require("@/assets/images/go-back.png"),
      courseVO: {
        course: {
          scheduleCnt: 0,
          saleCount: 0,
          showPrice: 0,
          truePrice: 0,
          tags: [],
        },
        scheduleList: [],
        ownThisCourse: 0,
        teacherList: [],
      },
      courseId: 0,
      showCsWidget: false,
      showLoginWidget: false,
    };
  },
  methods: {
    getId() {
      if (this.$route.query && this.$route.query.id) {
        this.courseId = this.$route.query.id;
      }
      return this.courseId;
    },
    getCourse(courseId) {
      courseApi.courseInfo(courseId).then((ret) => {
        if (ret && ret.code == 0 && ret.data) {
          this.courseVO = ret.data;
          console.log("courseInfo...", this.courseVO);
        }
      });
    },
    onClickTab(active) {
      this.active = active;
    },
    onClickShowCsWidget() {
      this.showCsWidget = true;
    },
    listenOnCloseCsWidget() {
      this.$EventBus.$on("onCloseCsWidget", (val) => {
        this.showCsWidget = false;
      });
    },
    listenOnCloseLoginWidget() {
      this.$EventBus.$on("onCloseLoginWidget", (val) => {
        this.showLoginWidget = false;
      });
    },
    onGoBack() {
      this.$router.go(-1);
    },
    gotoTeach(schedule) {
      const { courseId, scheduleId, teachId } = schedule;
      if (!this.courseVO.isLogin) {
        localStorage.clear();
        this.showLoginWidget = true;
        return;
      }
      if (this.courseVO.ownThisCourse || schedule.canPlay) {
        if (schedule.teachType == 3) {
          // 信息流
          this.$router.push({
            path: "/teach/talk",
            query: {
              c: courseId,
              s: scheduleId,
              t: teachId,
            },
          });
        } else if (schedule.teachType == 5) {
          //recite背诵
          this.$router.push({
            path: "/teach/book",
            query: {
              c: courseId,
              s: scheduleId,
              t: teachId,
            },
          });
        } else if (schedule.teachType == 4 || schedule.teachType == 0) {
          window.location.href = `http://lab.kaiweitalk.com/#${schedule.url}`;
        }
        return;
      } else {
        Notify({
          type: "warning",
          message: "本小节需要购买哟 ～",
        });
      }
      if (schedule.authType == 1 && schedule.canPlay == false) {
        // 1代表注册学
        // 需要登录
        localStorage.clear();
        this.showLoginWidget = true;
      }
    },
    onBuy() {
      // const paymentLink = 'http://pay.kaiweitalk.com/payment?courseId=' + this.courseId;
      if (this.courseVO.ownThisCourse == 1) {
        return;
      }
      let price = parseFloat(this.courseVO.course.truePrice);

      //window.location.href = paymentLink;
      const isWeiXinFlag = isWeiXin();
      const isMobileFlag = isMobile();
      console.log("isWeixin", isWeiXinFlag, "isMobile", isMobileFlag);
      userCenterApi
        .uid()
        .then((ret) => {
          console.log(ret);
          if (ret.code == 0 && ret.data) {
            if (price > 0) {
              // 付费课
              if (isWeiXinFlag) {
                this.$router.push({
                  path: "pay/wxpay",
                  query: { id: this.courseId },
                });
                return;
              }
              if (isMobileFlag) {
                this.$router.push({
                  path: "pay/h5pay",
                  query: { id: this.courseId },
                });
                return;
              }
              this.$router.push({
                path: "pay/pcpay",
                query: { id: this.courseId },
              });
            } else {
              // 免费课
              orderApi.freeOrder(this.courseId).then((res) => {
                console.log(res);
                if (res.code == 0 && res.data) {
                  Notify({
                    type: "success",
                    message: "加入成功",
                  });
                  this.getCourse(this.courseId);
                }
              });
            }
          } else {
            console.log("show login widget");
            localStorage.clear();
            this.showLoginWidget = true;
          }
        })
        .catch((err) => {
          userCenterApi.logoutV2().then((ret) => {
            localStorage.clear();
            this.showLoginWidget = true;
          });
        });
    },
  },
  mounted() {
    const courseId = this.getId();
    if (courseId) {
      this.getCourse(courseId);
    }
    this.listenOnCloseCsWidget();
    this.listenOnCloseLoginWidget();
  },
};
</script>

<style lang="less">
.introduction {
  font-size: 14px;
  color: #323233;
  letter-spacing: 0.31px;
  width: 100%;
  img {
    width: 100%;
    height: auto;
  }
}
</style>

<style lang="less" scoped>
.course-detail {
  display: flex;
  flex-direction: column;
  height: 100%;
  .main {
    overflow-y: scroll;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    .cover {
      min-height: 11.7rem;
      width: 100%;
      background-size: 100% 100%;
      display: flex;
      .go-back {
        margin: 28px 0 0 16px;
        height: 22px;
        width: auto;
        img {
          width: auto;
          height: 22px;
        }
      }
    }
    .panel {
      margin: -20px 0 0 0;
      background: #ffffff;
      border-radius: 16px 16px 0 0;
      // flex: 1;
      .title {
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.4px;
        padding: 18px 0 0px 17px;
        margin: 0 0 6px 0;
      }
      .tag-list {
        margin: 0 0 18px 17px;
        .tag {
          margin: 0 6px;
          padding: 2px 7px;
          background: rgba(77, 82, 209, 0.1);
          border-radius: 6px;
          font-size: 12px;
          color: #4d52d1;
          letter-spacing: 0.27px;
        }
        .warn {
          background: #fef0e5;
          color: #ff8657;
        }
      }
      .info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0 0 10px 17px;
        .sale-info {
          font-size: 14px;
          color: #848484;
          letter-spacing: 0.31px;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
        }
        .price-info {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          .show {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
            font-size: 14px;
            color: #848484;
            letter-spacing: 0.31px;
            line-height: 17px;
            margin-right: 10px;
            text-decoration: line-through;
          }
          .true {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 26px;
            color: #f86e00;
            font-weight: 600;
            letter-spacing: 0.58px;
            line-height: 25px;
            margin-right: 23px;
            .yuan {
              font-size: 18px;
            }
          }
        }
      }
      .line {
        height: 6px;
        background: rgb(245, 246, 248);
      }
      .tab-info {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        .tab {
          display: flex;
          font-size: 16px;
          color: #797979;
          letter-spacing: 0.36px;
          height: 60px;
          flex: 1;
          align-items: center;
          text-align: center;
          justify-content: center;
          margin: 0 auto;
          border-bottom: 2px solid;
          border-bottom-color: rgb(215, 215, 215);
        }
        .active {
          border-bottom-color: #4d52d1;
          color: #4d52d1;
          font-weight: 500;
        }
      }
      .schedule {
        margin: 0 20px;
        .chapter {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #d9d9d9;
          .name {
            // height: 60px;
            font-weight: 600;
            font-size: 16px;
            color: #323233;
            letter-spacing: 0.36px;
            display: flex;
            align-items: center;
            width: 330px;
            padding: 17px 0;
          }
          .tag {
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 18px;
              height: auto;
            }
          }
        }
        .learn-section {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #d9d9d9;
          .name {
            display: flex;
            align-items: center;
            height: 40px;
            font-size: 14px;
            color: #323233;
            letter-spacing: 0.31px;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 300px;
          }
          .tag {
            display: flex;
            align-items: center;
            border: 1px solid #4d52d1;
            border-radius: 4px;
            color: #4d52d1;
            width: 40px;
            height: 16px;
            justify-content: center;
            font-size: 12px;
            color: #4d52d1;
            letter-spacing: 0.27px;
            font-weight: 500;
          }
        }
      }
      .teachers-intro {
        .teacher {
          display: flex;
          flex-direction: row;
          border-bottom: 1px solid rgb(238, 238, 238);
          padding: 31px 20px 10px 0;
          .left {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            img {
              width: 45px;
              height: auto;
              margin: 0 7px 0px 19px;
            }
          }
          .right {
            display: flex;
            flex-direction: column;
            .name {
              font-size: 14px;
              color: #323233;
              letter-spacing: 0.31px;
              font-weight: 500;
            }
            .detail {
              padding: 5px 0 0 0;
              font-size: 12px;
              color: #797979;
              letter-spacing: 0.27px;
            }
          }
        }
      }
    }
  }
  .footer {
    flex: 0 0 auto;
    background: #fff;
    height: 72px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #d9d9d9;
    .price-info {
      margin: 0 0 0 17px;
      .show {
        font-size: 14px;
        color: #848484;
        letter-spacing: 0.31px;
        line-height: 17px;
        margin-right: 10px;
        text-decoration: line-through;
      }
      .true {
        font-size: 26px;
        color: #f86e00;
        letter-spacing: 0.58px;
        line-height: 25px;
        margin-right: 23px;
      }
    }
    .button-info {
      .buy {
        width: 140px;
        height: 46px;
        background: #ff8423;
        box-shadow: 0 2px 4px 0 #ff801b;
        border-radius: 16px;
        font-size: 20px;
        color: #ffffff;
        letter-spacing: 0.44px;
        font-weight: 500;
        border: none;
      }
      .disable {
        background: #c7c7c7;
        box-shadow: 0 2px 4px 0 #c7c7c7;
      }
    }
  }
  .cs {
    height: 50px;
    width: 50px;
    position: absolute;
    left: 23px;
    bottom: 130px;
    z-index: 1;
    img {
      width: 50px;
      height: auto;
    }
  }
}
</style>
